export const THEME_TOKEN = 'THEME';

export const THEME_API_PATHS = {};

export const DEFAULT_THEME = {
    typeface: 'Inter',
    // * PRIMARY COLORS
    primary_color_darker: '#394e66',
    primary_color_dark: '#394e66',
    primary_color: '#394e66',
    primary_color_light: '#394e66',
    primary_color_lighter: '#394e66',

    // * SECONDARY COLORS
    secondary_color_darker: '#1c4598',
    secondary_color_dark: '#1c4598',
    secondary_color: '#2353AF',
    secondary_color_light: '#1155cc',
    secondary_color_lighter: '#edf6fe',

    // * LOADER COLORS
    loader_outside_circle: '#3498db',
    loader_middle_circle: '#e74c3c',
    loader_inside_circle: '#f9c922',

    // * WARN COLORS
    info_color_lighter: '#e9f6fe',
    info_color_light: '#61a2ed',
    info_color: '#2353af',

    error_color_lighter: '#fdeae9',
    error_color_light: '#f8ada7',
    error_color: '#be271d',

    warning_color_lighter: '#fffae6',
    warning_color_light: '#efca61',
    warning_color: '#806800',

    success_color_lighter: '#e9f7ee',
    success_color_light: '#7ecc94',
    success_color: '#18662f',

    // * LINK COLORS
    link_primary_color: '#394e66',
    link_primary_hover_color: '#394e66',

    link_secondary_color: '#63788f',
    link_secondary_hover_color: '#4f657e',

    // * ICON COLORS
    icon_primary_color: '#394e66',
    icon_secondary_color: '#394e66',

    // * TEXT COLORS
    text_color: '#394e66',
    text_color_light: '#a3b3c6',

    heading_primary_color: '#172a41',
    heading_secondary_color: '#293b51',

    button_primary_text_color: '#fff',
    button_secondary_text_color: '#fff',
    button_border_radius: '8px',

    // * SHADOW COLORS
    box_shadow_color: '#e8eaec',

    // * BORDER RADIUS
    border_radius_1: '8px',
    border_radius_2: '6px',
    border_radius_3: '4px',

    // * OUTLINE COLORS
    outline_color_1: '#bfccda',
    outline_color_2: '#dbe2eb',
    outline_color_3: '#949494',
    outline_color_4: '#63788f',
    outline_color_5: '#394e66',

    // * BACKGROUND COLORS
    background_color_0: '#ffffff',
    background_color_1: '#f0f3f7',
    background_color_2: '#f7f9fa',
    background_color_3: '#b8bdc7',
    background_color_4: '#63788f',
    background_color_5: '#394e66',

    // * SOCIAL COLORS
    facebook_color: '#1877f2',
    google_color: '#ea4335',
    product_review: '#152f29',

    // * TEXT COLORS
    text_color_gray: '#394e66',
    text_color_gray_light: '#717171',
    text_color_gray_lighter: '#cecece',
    text_color_black: '#000000',
    text_color_white: '#ffffff',

    // * OVERLAYS COLORS
    overlay_background_color: 'rgba(0, 0, 0, 0.4)',

    // * SCROLLBAR COLORS
    scrollbar_track_color: '#dbe2eb',
    scrollbar_thumb_color: '#1155cc',

    // * SKELETON COLORS
    skeleton_linear_gradient: '#f4f4f4',
    skeleton_linear_gradient_second: '#949494',
    lr_linear: '#394e66',
    lr_linear_second: '#f4f4f4',

    /// !! COMPONENTS AND CUSTOM COLORS BASE ON PROJECT !!
    // * CUSTOM COLORS
    app_background: '#ffffff',
    popular_services_background: '#f0f3f7',
    membership_exit_icon_background: 'rgba(14, 92, 152, 0.05)',
    background_color_light: '#ffffff',
    background_color_dark: '#f0f3f7',
    background_color_slot_row: '#e9f6fe',
    background_secondary_color: '#ffffff',
    background_timeslot_date: '#ffffff',
    background_app_header: '#ffffff',
    background_app_content: '#ffffff',
    background_footer: '#eef2f8',
    background_notifications_items: "#ffffff",
    concierge_background: "#f0f3f7",
    menu_separators: "#dbe2eb",

    // * SIDEBAR COLORS
    background_sidebar: '#fff',
    background_sidebar_header: '#edf6fe',
    background_sidebar_header_text: '#394e66',

    // // ***** ! DEALS VIEW MODULE ! ******
    // deals_view_text_color: '#394e66',
    // deals_view_heading_secondary_color: '#293b51',
    // treats_view_text_color: '#394e66',
    // treats_view_heading_secondary_color: '#293b51',
    // // ** DEALS CARD COMPONENT COLORS
    // deals_card_background_color_0: '#ffffff',
    // deals_card_icon_secondary_color: '#63788f',
    // deals_card_link_secondary_color: '#63788f',
    // deals_card_heading_secondary_color: '#293b51',
    // deals_card_primary_color: '#DB1A7D',
    // deals_card_button_primary_text_color: '#ffffff',

    // // ** LOADE MORE COMPONENT COLORS
    // load_more_icon_secondary_color: '#63788f',
    // load_more_link_secondary_color: '#63788f',

    // // ** NAV TABS COMPONENT COLORS
    // nav_tabs_secondary_color: '#1155CC',
    // nav_tabs_link_primary_color: '#1155CC',
    // nav_tabs_link_secondary_color: '#63788f',
    // nav_tabs_icon_primary_color: '#1155CC',
    // nav_tabs_icon_secondary_color: '#63788f'
};
