<!-- <pre>{{ sessions | json }}</pre> -->

<div class="upcoming-sessions {{ documentDirection }}">
    <div class="sessions-month" *ngFor="let sessionMonth of sessions">
        <div class="month-title">
            {{sessionMonth.monthY}}
        </div>
        <div class="d-flex session" *ngFor="let session of sessionMonth.sessions">
            <div class="session-info-wrap">
                <div class="session-info" *ngIf="session.arrival_timeframe_formatted">
                    <serviceos-ng-system-icon [icon]="'clock-time-start-at'">
                    </serviceos-ng-system-icon>
                    {{session.arrival_timeframe_formatted}}
                </div>
            </div>
            <serviceos-ng-link [type]="'secondary'" (click)="selectedSession(session.objectId)">
                {{TextOptions.selectingSessionLink}}
            </serviceos-ng-link>
        </div>
    </div>
</div>