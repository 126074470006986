import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    FormControl,
    NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Store } from '@ngxs/store';
import { LocalizationSelectors } from '@serviceos-ng/core';

// Increasing integer for generating unique ids for slide-toggle components.
let nextUniqueId = 0;

/** @docs-private */
export const SLIDE_TOGGLE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SlideToggleComponent),
    multi: true,
};

@Component({
    selector: 'serviceos-ng-slide-toggle',
    templateUrl: './slide-toggle.component.html',
    host: {
        class: 'sos-slide-toggle',
        '[id]': 'id',
        '[attr.name]': 'null',
        '[class.sos-checked]': 'checked',
        '[class.sos-disabled]': 'disabled',
    },
    styleUrls: ['./slide-toggle.component.scss'],
    providers: [SLIDE_TOGGLE_ACCESSOR],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleComponent implements ControlValueAccessor {
    private _checked: boolean = false;

    public documentDirection = '';

    @Input()
    disabled = false;

    /** Whether the slide-toggle element is checked or not. */
    @Input()
    get checked(): boolean {
        return this._checked;
    }
    set checked(value: BooleanInput) {
        this._checked = coerceBooleanProperty(value);
        this._changeDetectorRef.markForCheck();
    }

    @Input() name: string | null = null;

    @Input() label: string | null = null;

    @Input() hideLabel: boolean  = false;

    private _uniqueId: string = `sos-slide-toggle-${++nextUniqueId}`;
    @Input() id: string = this._uniqueId;

    get inputId(): string {
        return `${this.id || this._uniqueId}-input`;
    }

    @ViewChild('input') _inputElement!: ElementRef<HTMLInputElement>;

    _onChange = (_: any) => {};

    _onTouched = () => {};

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private store: Store
    ) {
        this.store.select(LocalizationSelectors.getCurrentDirection()).subscribe(
            (currentDirection) => {
                if (currentDirection) {
                    this.documentDirection = currentDirection;
                }
            }
        )
    }

    public writeValue(value: any) {
        this._checked = !!value;
        this._emitChangeEvent();
    }

    public registerOnChange(onChangeFunction: (value) => void) {
        this._onChange = onChangeFunction;
    }

    public registerOnTouched(onTouchedFunction: () => void) {
        this._onTouched = onTouchedFunction;
    }

    _onInputClick(event: Event) {
        event.stopPropagation();
    }
    /** View method */

    /** Toggles the checked state of the slide-toggle. */
    toggle(): void {
        this.checked = !this.checked;
        this._onChange(this.checked);
    }

    /** Method being called whenever the underlying input emits a change event. */
    _onChangeEvent(event: Event) {
        // We always have to stop propagation on the change event.
        // Otherwise the change event, from the input element, will bubble up and
        // emit its event object to the component's `change` output.
        event.stopPropagation();

        // When the slide toggle's config disables toggle change event by setting
        // `disableToggleValue: true`, the slide toggle's value does not change, and the
        // checked state of the underlying input needs to be changed back.
        if (this.disabled) {
            this._inputElement.nativeElement.checked = this.checked;
            return;
        }

        // Sync the value from the underlying input element with the component instance.
        this.checked = this._inputElement.nativeElement.checked;

        // Emit our custom change event only if the underlying input emitted one. This ensures that
        // there is no change event, when the checked state changes programmatically.
        this._emitChangeEvent();
    }

    /**
     * Emits a change event on the `change` output. Also notifies the FormControl about the change.
     */
    private _emitChangeEvent() {
        this._onChange(this.checked);
    }
}
