<section *ngIf="topBarConfig" class="top-bar" [ngClass]="{'centered': topBarConfig && topBarConfig.centered}">
    <span *ngIf="!topBarConfig.href; else redirectUrl" [innerHTML]="topBarConfig.message"></span>
    <ng-template #redirectUrl>
        <a [href]="topBarConfig.href" [innerHTML]="topBarConfig.message"></a>
    </ng-template>
</section>
<div class="header">
    <ng-container *ngIf="navigationState$ | async as navigationState">
        <div
            *ngIf="navigationState.haveNavigation && navigationState.mobileView"
            class="menu-toggle-holder"
        >
            <div class="notification" *ngIf="mobileMenuNotifications"></div>
            <serviceos-ng-system-icon
                class="hamburger-menu"
                [icon]="'hamburger'"
                (click)="toggleMenu()"
            ></serviceos-ng-system-icon>
        </div>
    </ng-container>
    <div class="logo-wrap" (click)="clickLogo()">
        <serviceos-ng-logo
            [logoUrl]="logo"
            [brandName]="logoText ? logoText : ''"
        ></serviceos-ng-logo>
    </div>
    <div>
        <ng-content #name></ng-content>
    </div>
</div>
