import { Injectable } from '@angular/core';
import { THEME_TOKEN, DEFAULT_THEME } from './theme.const';
import {
  Action,
  Selector,
  State,
  StateContext,
  NgxsAfterBootstrap,
} from '@ngxs/store';
import { ThemeActions } from './theme.actions';
import {
  toRGBStyle,
  rgbShade,
  hexToRgb,
  rgbTint,
} from '../../../../helpers';
import { CoreActions } from '../core.actions';
import { FeaturesActions } from '../features/features.actions';

export class ThemeStateModel {
  theme = DEFAULT_THEME;
  status?: 'pending' | 'error' | 'success' = 'pending';
}

@State<ThemeStateModel>({
  name: THEME_TOKEN,
  defaults: new ThemeStateModel(),
})
@Injectable()
export class ThemeState {
  @Action(ThemeActions.SaveThemeColors)
  SaveThemeColors(
    { patchState, getState, dispatch }: StateContext<ThemeStateModel>,
    payload
  ) {
    const currentTheme = getState().theme;
    const theme = JSON.parse(JSON.stringify(currentTheme));
    const configThemeColors = payload.theme.colors;
    const configTheme = payload.theme;
    // const theme = JSON.parse(JSON.stringify(DEFAULT_THEME));

    //* Generate colors base on passed primary and secondary to update the theme.
    if (
      configThemeColors['primary_color'] &&
      configThemeColors['primary_color'] !== currentTheme['primary_color']
    ) {
      theme.primary_color_darker = toRGBStyle(
        rgbShade(hexToRgb(configThemeColors['primary_color']), 0.7)
      );
      theme.primary_color_dark = toRGBStyle(
        rgbShade(hexToRgb(configThemeColors['primary_color']), 0.5)
      );
      theme.primary_color = configThemeColors['primary_color'];
      theme.primary_color_light = toRGBStyle(
        rgbTint(hexToRgb(configThemeColors['primary_color']), 0.8)
      );
      theme.primary_color_lighter = toRGBStyle(
        rgbTint(hexToRgb(configThemeColors['primary_color']), 0.8)
      );
      theme.loader_outside_circle = configThemeColors['primary_color'];
      theme.loader_inside_circle = configThemeColors['primary_color'];
    }

    if (
      configThemeColors['secondary_color'] &&
      configThemeColors['secondary_color'] !== currentTheme['secondary_color']
    ) {
      theme.secondary_color_darker = toRGBStyle(
        rgbShade(hexToRgb(configThemeColors['secondary_color']), 0.5)
      );
      theme.secondary_color_dark = toRGBStyle(
        rgbShade(hexToRgb(configThemeColors['secondary_color']), 0.5)
      );
      theme.secondary_color = configThemeColors['secondary_color'];
      theme.secondary_color_light = configThemeColors['secondary_color'];
      theme.secondary_color_lighter = toRGBStyle(
        rgbTint(hexToRgb(configThemeColors['secondary_color']), 0.9)
      );
      
      theme.link_primary_color = configThemeColors['secondary_color'];
      theme.link_primary_hover_color =  theme.secondary_color_darker;
      theme.icon_primary_color = configThemeColors['secondary_color'];
      theme.scrollbar_thumb_color = configThemeColors['secondary_color'];
      theme.loader_middle_circle = configThemeColors['secondary_color'];
    }

    //* Apply if the are custom specify colors for the theme expect primary and secondary color.
    Object.keys(theme).map((key) => {
      if (configThemeColors[key]) {
        theme[key] = configThemeColors[key];
      }
    });

    //* Set font (typeface)
    if (configTheme?.fonts?.primary) {
      theme['typeface'] = configTheme?.fonts?.primary;
      theme['secondary_typeface'] = configTheme?.fonts?.primary;
    }
    if (configTheme?.fonts?.secondary) {
      theme['secondary_typeface'] = configTheme?.fonts?.secondary;
    }

    //* Update the state
    patchState({ theme, status: 'success' });
    return dispatch(new ThemeActions.ApplyThemeColors());
  }

  @Action(ThemeActions.ApplyThemeColors)
  ApplyThemeColors({ getState }: StateContext<ThemeStateModel>) {
    const theme = getState().theme;

    // NOTE Set colors on root because we need to style modals too.
    const rootComponent: HTMLElement | null = document.documentElement;
    // .querySelector(
    //   'serviceos-web-root serviceos-web-accounts-root'
    // );

    if (theme) {
      Object.keys(theme).map((key) => {
        if (rootComponent) {
          rootComponent.style.setProperty(`--${key}`, theme[key]);
        }
      });
    }
  }
}
