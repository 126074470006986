import { Injectable } from '@angular/core';
import {
    NavigationItemInterface,
    NAVIGATION_STATE_TOKEN,
    RouteConfigInterface,
} from './navigation.const';
import {
    Action,
    Selector,
    State,
    StateContext,
    Store,
    NgxsOnInit,
} from '@ngxs/store';
import { NavigationActions } from './navigation.actions';
import { BookingsSelectors } from '../bookings-store/bookings/bookings.selectors';
import { NotificationsSelectors } from '../account-store/notifications/notifications.selectors';

export class NavigationStateModel {
    items: NavigationItemInterface[] = [];
    route: RouteConfigInterface = {
        pathname: '',
        params: '',
        data: {}
    }
}

@State<NavigationStateModel>({
    name: NAVIGATION_STATE_TOKEN,
    defaults: new NavigationStateModel(),
})
@Injectable()
export class NavigationState implements NgxsOnInit {
    constructor(private store: Store) {}

    ngxsOnInit(ctx?: StateContext<any> | undefined) {
        ctx?.dispatch(new NavigationActions.Initialize()).subscribe();
    }

    @Action(NavigationActions.Initialize)
    initialize({ dispatch, setState }: StateContext<NavigationStateModel>) {
        setState({ items: [], route: { pathname: '', params: '', data: {}} });

        dispatch(new NavigationActions.SetRouteConfig(window.location.pathname, window.location.search));
    }

    @Action(NavigationActions.AddNavigationItems)
    AddNavigationItems(
        { dispatch, getState, patchState }: StateContext<NavigationStateModel>,
        { navigationItems }: NavigationActions.AddNavigationItems
    ) {
        const currentNavigationItems = JSON.parse(JSON.stringify(getState().items));

        // Update
        let updatedNavItems = currentNavigationItems.map((navigationItem) => {
            let itemForUpdate = false;
            let updateNavItem;
            navigationItems = navigationItems.filter((item) => {
                if (item.path === navigationItem.path) {
                    updateNavItem = item;
                    itemForUpdate = true;
                    return false;
                }
                return true;
            });

            if (itemForUpdate && updateNavItem) {
                return { ...navigationItem, ...updateNavItem };
            } else {
                return navigationItem;
            }
        });
        // Add the new navigation items if the are some and sort the navigation items.
        updatedNavItems = [...updatedNavItems, ...navigationItems].sort(
            (a, b) => a.sort - b.sort
        );

        patchState({ items: updatedNavItems });
    }

    @Action(NavigationActions.SetRouteConfig)
    SetRouteConfig(
        { dispatch, getState, patchState }: StateContext<NavigationStateModel>,
        payload: NavigationActions.SetRouteConfig
    ) {

        // Do not add referral page into route config, you dont need to come back to this page after authotization
        if (payload?.pathname?.indexOf('referral') > -1) return;
        
        let data = {};
        
        if (payload?.params) {

            try {
                data = JSON.parse('{"' + decodeURI(payload.params.substring(1).replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
              } catch (error) {
                // data will remain empty if error occur when parsing
              }
            
        }
        
        patchState({ route: { pathname: payload.pathname, params: payload.params, data: data}} );
    }

    @Action(NavigationActions.ClearRouteConfig)
    ClearRouteConfig(
        { patchState }: StateContext<NavigationStateModel>
    ) {
        patchState({ route: { pathname: '', params: '', data: {}}} );
    }

    @Action(NavigationActions.UpdateCounter)
    updateCounter(
        { dispatch, patchState, getState }: StateContext<NavigationStateModel>,
        payload: NavigationActions.UpdateCounter
    ) {
        let items = [...getState().items];

        items = items.map((el) => {
            el = { ...el };
            if (el.path == payload.path) {
                el.count = payload.count;
            }

            return el;
        });

        patchState({ items: JSON.parse(JSON.stringify(items))});
    }
}
