import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { LocalizationSelectors } from '@serviceos-ng/core';

@Component({
  selector: 'serviceos-web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'accounts';

  constructor(
    private store: Store
  ) {
    
  }

  ngOnInit(): void {
    this.store.select(LocalizationSelectors.getCurrentLocalization()).subscribe(
      (currentLocalization) => {
        if (currentLocalization?.text_direction) {
          document.dir = currentLocalization.text_direction;
        }
      }
    )
  }

}

